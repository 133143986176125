import { Order } from 'utils/types/order';

const planIdentifiersMap = {
    mensal: '8',
    anual: '6',
    bianual: '12',
};

const pushEvent = (order: Order, event: string, installment?: number) => {
    const dataLayer = window?.dataLayer || [];

    if (order) {
        const price = (order.plan.price / order.plan.installments).toFixed(1);

        const installmentOption = order.payment?.installmentOptions?.find(
            (option) => option?.installment === installment
        );

        const finalPrice = installmentOption?.amount * installment;

        const items = {
            currency: 'BRL',
            item_id: planIdentifiersMap[order.planType] || '',
            item_name: order.plan.name,
            price: Number(price),
            quantity: order.plan.cycleDuration,
        };

        dataLayer?.push({
            event: event,
            items: items,
            value: order.plan.price,
            final_value: finalPrice || undefined,
            is_winback: order.isWinback,
            payment_method: order.payment?.paymentMethod,
            discount_percentage: installmentOption?.discount,
        });
    }
};

export default pushEvent;
