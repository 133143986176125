type StorageKey = 'userId';

const LocalStorageHelper = {
    get<T>(key: StorageKey) {
        const item = localStorage.getItem(key);
        if (!item) return undefined;

        return JSON.parse(item) as T;
    },
    set: (key: StorageKey, value: unknown) => {
        localStorage.setItem(key, JSON.stringify(value));
    },
};

export default LocalStorageHelper;
