export const getYears = (startedYear) => {
    const currentYear = new Date().getFullYear();
    let startYear = startedYear;
    const years: string[] = [];
    while (startYear <= currentYear) {
        years.push(String(startYear));
        startYear++;
    }
    return years.reverse();
};

export const diffInYears = (firstDate: Date, secondDate: Date) => {
    const MS_PER_YEAR = 1000 * 60 * 60 * 24 * 365;
    const diffInTime = Math.abs(firstDate.getTime() - secondDate.getTime());

    return Math.ceil(diffInTime / MS_PER_YEAR);
};

export const isCurrentSemester = (
    ingressSemester: number,
    ingressYear: number
) => {
    const today = new Date();
    return (
        ingressSemester === Math.ceil((today.getMonth() + 1) / 6) &&
        ingressYear === today.getFullYear()
    );
};
