import { useRouter } from 'next/router';
import { submitHubspotFormRetry } from '@sanardigital/hubspot-client';
import env from '@beam-australia/react-env';
import { HubspotFormField } from '@sanardigital/hubspot-client/dist/main/interfaces';
import logErrorOnNewRelic from '../../utils/newRelic/logErrorOnNewRelic';
import { formatDateToString } from '../../utils/format/date';
import {
    extractCampaignFieldsFromUrl,
    legalConsentOptions,
    calculateTrainingStage,
} from './hubspot.helper';
import { onlyNumbers } from '../../utils/format/only-numbers';

interface RegistrationFields {
    name: string;
    email: string;
    year: string;
    semester: string;
    ddi: string;
    telephone: string;
}

interface PaymentFields {
    plan: string;
    amount: number;
    userId: string;
    email: string;
    coupon?: string;
    isWinback: boolean;
    createdAt: Date;
}

const PAGE_NAME = 'Sanarflix Checkout';

const useHubspot = () => {
    const { query } = useRouter();

    const submitForm = async (fields: HubspotFormField[], formId: string) => {
        if (env('ENVIRONMENT') === 'prod' || env('ENABLE_HUBSPOT') === 'true') {
            try {
                const response = await submitHubspotFormRetry(
                    {
                        fields: [
                            ...fields,
                            ...extractCampaignFieldsFromUrl(query),
                        ],
                        legalConsentOptions,
                        context: {
                            pageName: PAGE_NAME,
                            pageUri: `${window.location.origin}${window.location.pathname}`,
                        },
                    },
                    formId,
                    env('HUBSPOT_PORTAL_ID'),
                    {
                        attempts: 3,
                        interval: 5000,
                    }
                );

                if (!response.success) {
                    if (!response.errors) throw new Error('Hubspot Error');

                    response.errors.map((err) => {
                        logErrorOnNewRelic(err.message, {
                            error: `Hubspot form submition failed for formId ${formId}`,
                            errorType: err.errorType,
                        });
                    });
                }
            } catch (err) {
                logErrorOnNewRelic(err, {
                    error: `Failed on submit form ${formId}`,
                });
            }
        }
    };

    const sendPersonalForm = async (
        formFields: RegistrationFields
    ): Promise<void> => {
        await submitForm(
            [
                { name: 'business_unit', value: 'sanarflix' },
                { name: 'contact__sf__origin_channel', value: 'Carrinho' },
                { name: 'fullname', value: formFields.name },
                { name: 'email', value: formFields.email },
                {
                    name: 'contact__gn__year_of_college_entry',
                    value: formFields.year,
                },
                {
                    name: 'contact__gn__semester_of_college_entry',
                    value: formFields.semester,
                },
                {
                    name: 'contact__gn__carrer_stage',
                    value: calculateTrainingStage(
                        formFields.year,
                        formFields.semester
                    ),
                },
                {
                    name: 'contact__sf__bait',
                    value: 'Checkout de SanarFlix',
                },
                {
                    name: 'phone',
                    value: onlyNumbers(
                        `${formFields.ddi}${formFields.telephone}`
                    ),
                },
            ],
            env('HUBSPOT_REGISTRATION_FORM_ID')
        );
    };

    const sendPaymentForm = async (
        formFields: PaymentFields
    ): Promise<void> => {
        submitForm(
            [
                { name: 'email', value: formFields.email },
                { name: 'contact__sf__sub_type', value: formFields.plan },
                { name: 'contact__gn__user_id', value: formFields.userId },
                {
                    name: 'contact__sf__monthly_pay',
                    value: formFields.amount.toString(),
                },
                { name: 'contact__sf__sub_status', value: 'Ativo' },
                { name: 'contact__gn__cupom', value: formFields.coupon || '' },
                {
                    name: 'contact__sf__buy_stage',
                    value: formFields.isWinback ? 'winback' : 'trial',
                },
                {
                    name: 'contact__sf__last_acquisition_date',
                    value: formatDateToString(formFields.createdAt),
                },
            ],
            env('HUBSPOT_PAYMENT_FORM_ID')
        );
    };

    return { sendPersonalForm, sendPaymentForm };
};

export default useHubspot;
