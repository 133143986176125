import { newCheckoutInstance } from 'services/lambda/instance';
import { logEvent } from 'services/lambda/log-event';
import { getSource } from 'services/lambda/get-source';
import { USER_ERROR } from 'contants/errors';
import logErrorOnNewRelic from 'utils/newRelic/logErrorOnNewRelic';

const path = '/createPaymentSubscription';

export const createPaymentSubscription = async (
    encrypted: string,
    captcha: string,
    token: string,
    userAgent: string
) => {
    try {
        const data = await newCheckoutInstance
            .post(
                path,
                {
                    captcha,
                    source: getSource(userAgent),
                    encrypted,
                },
                { headers: { Authorization: token } }
            )
            .then(({ data }) => data);

        return data;
    } catch (error) {
        logErrorOnNewRelic(error, {
            error: 'Error on createPaymentSubscription',
        });
        logEvent({ error, path });
        throw error?.response?.data?.code || USER_ERROR.code;
    }
};
