import React from 'react';
import { BiLoaderCircle } from 'react-icons/bi';
import styled, { keyframes } from 'styled-components';

import { Box } from 'components/Box';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled(BiLoaderCircle)`
    animation: ${rotate} 2s linear infinite;
    width: 48px;
    height: 48px;
`;

export const Loading = ({ height = '100vh' }) => {
    return (
        <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            height={height}
            color='primary'
        >
            <Box
                as='img'
                src='https://plataforma-sanarflix.s3-sa-east-1.amazonaws.com/logos/logo-inline.png'
                alt='Logo SanarFlix'
                mb='3'
                width='200px'
            />
            <LoadingIcon key='loading' size='36' />
        </Box>
    );
};
