import React from 'react';
import { Box } from 'components/Box';
import { Button } from 'components/Button';
import { Text } from 'components/Text';
import Lottie from 'react-lottie';
import animationData from 'animations/failed.json';
import env from '@beam-australia/react-env';

export const InvalidPlan = ({ height = '100vh' }) => {
    return (
        <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            height={height}
            color='primary'
        >
            <Box mx='auto' id='failed'>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                        },
                    }}
                    width={120}
                />
            </Box>
            <Text
                fontSize='2xl'
                my='4'
                mx='3'
                color='black'
                fontWeight='bold'
                textAlign='center'
            >
                Ops, esse plano não é válido!
            </Text>

            <Box borderRadius='lg' bg='red.50' px='6' py='3' mb='2' mx='3'>
                <Text textAlign='center' fontWeight='semibold' color='red.900'>
                    O plano que você está tentando comprar não existe ou foi
                    desabilitado.
                </Text>
            </Box>

            <Text
                fontSize='lg'
                color='gray.800'
                textAlign='center'
                mt='6'
                mx='3'
            >
                Confira os planos disponíveis clicando no botão abaixo:
            </Text>
            <Button
                mt='8'
                variant='solid'
                variantColor='primary'
                size='lg'
                borderRadius='8px'
                as='a'
                href={
                    env('FLIX_LP_ASSINE_URL') ||
                    'http://www.sanarflix.com.br/assine/'
                }
            >
                Ver Planos
            </Button>
        </Box>
    );
};
