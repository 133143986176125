import { newCheckoutInstance } from 'services/lambda/instance';
import { logEvent } from 'services/lambda/log-event';
import { getSource } from 'services/lambda/get-source';
import { USER_ERROR } from 'contants/errors';
import logErrorOnNewRelic from 'utils/newRelic/logErrorOnNewRelic';

const request = async (
    encrypted: string,
    token: string,
    userAgent: string,
    path: string
) => {
    try {
        const data = await newCheckoutInstance
            .post(
                path,
                {
                    source: getSource(userAgent),
                    encrypted,
                },
                { headers: { Authorization: token } }
            )
            .then(({ data }) => data);

        return data;
    } catch (error) {
        logErrorOnNewRelic(error, {
            error: `Error on request: ${path}`,
        });
        logEvent({ error, path });
        throw error?.response?.data?.code || USER_ERROR.code;
    }
};

export const findOrCreateUser = async (
    encrypted: string,
    token: string,
    userAgent: string
) => {
    const path = '/findOrCreateUser';
    return request(encrypted, token, userAgent, path);
};

export const existsInPayments = async (
    encrypted: string,
    token: string,
    userAgent: string
) => {
    const path = '/existsInPayments';
    return request(encrypted, token, userAgent, path);
};
