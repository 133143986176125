import NodeRSA from 'node-rsa';

import { logEvent } from 'services/lambda/log-event';
import { PURCHASE_REJECTED } from 'contants/errors';
import logErrorOnNewRelic from 'utils/newRelic/logErrorOnNewRelic';

export const encrypt = (text: string, publicKey: string) => {
    try {
        const key = new NodeRSA(publicKey);
        const encrypted = key.encrypt(text, 'base64');

        return encrypted;
    } catch (error) {
        logErrorOnNewRelic(error, {
            error: 'Error on encrypt',
        });

        logEvent({ error, path: 'encrypt' });
        throw PURCHASE_REJECTED.code;
    }
};
