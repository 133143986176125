import { HubspotFormLegalConsent } from '@sanardigital/hubspot-client/dist/main/interfaces';
import { ParsedUrlQuery } from 'querystring';
import { diffInYears } from '../../utils/common/getYears';

const MAX_TRAINING_STAGE = 6;
const startMonthOfSemesterMap = { '1': 1, '2': 7 };

const getSingleParam = (param?: string[] | string) => {
    if (!param) return '';
    if (typeof param === 'string') return param;

    return param[0];
};

export const extractCampaignFieldsFromUrl = (query: ParsedUrlQuery) => [
    { name: 'utm_source', value: getSingleParam(query.utm_source) },
    { name: 'utm_term', value: getSingleParam(query.utm_term) },
    { name: 'utm_campaign', value: getSingleParam(query.utm_campaign) },
    { name: 'utm_medium', value: getSingleParam(query.utm_medium) },
    { name: 'utm_content', value: getSingleParam(query.utm_content) },
];

export const calculateTrainingStage = (year: string, semester: string) => {
    const ingressDate = new Date(
        parseInt(year),
        startMonthOfSemesterMap[semester] - 1
    );

    const result = diffInYears(new Date(), ingressDate);

    return result > MAX_TRAINING_STAGE ? 'superior_completo' : `${result}_ano`;
};

export const legalConsentOptions: HubspotFormLegalConsent = {
    legitimateInterest: {
        value: true,
        subscriptionTypeId: 19440592,
        legalBasis: 'LEAD',
        text: 'Ao assinar o SanarFlix, voce concorda com os Termos de Uso e Privacidade da plataforma.',
    },
};
